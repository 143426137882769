html,
body,
#root {
  height: 100vh;
  background: url("../images/Light_burst_gradient.jpg");
  background-color: #120f22;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif, "Space Grotesk", "Power Grotesk";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  transition: 0.3s all;
}

body {
  overscroll-behavior: none;
}

/* Scroll styles */

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;
  background: rgba(255, 255, 255, 10%); */
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 90%);
  border-radius: 10px;
  height: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

input:-internal-autofill-selected {
  background: transparent !important;
}

/* Scroll styles -- */

/* ============= Common CSS ============= */

.text-center {
  text-align: center;
}

.w-100 {
  width: 100%;
}

.w-100-imp {
  width: 100% !important;
}

.h-100 {
  height: 100%;
}

.h-100vh {
  height: 100vh;
}

.h-resized {
  height: calc(100vh - 73px);
}

.max-h-100vh {
  max-height: 100vh;
}

.bg-transparent {
  background: transparent;
}

.overflow-hidden {
  overflow: hidden;
}

.visibility-hidden {
  visibility: hidden;
}

.scroll-y {
  overflow-y: scroll;
}

.scroll-x {
  overflow-x: scroll;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.d-flex-col {
  display: flex;
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.all-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.all-fixed-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.no-gutter {
  gap: 0 !important;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.gap-20 {
  gap: 20px;
}

.font-9 {
  font-size: 9px;
  letter-spacing: -0.01em;
}

.font-12 {
  font-size: 12px;
  letter-spacing: -0.01em;
}

.font-14 {
  font-size: 14px;
  letter-spacing: -0.01em;
}

.font-16 {
  font-size: 16px;
  letter-spacing: -0.01em;
}

.font-18 {
  font-size: 18px;
  letter-spacing: -0.01em;
}

.font-20 {
  font-size: 20px;
  letter-spacing: -0.01em;
}

.font-22 {
  font-size: 22px;
  letter-spacing: -0.01em;
}

.font-24 {
  font-size: 24px;
}

.font-25 {
  font-size: 25px;
}

.font-26 {
  font-size: 26px;
  letter-spacing: -0.01em;
}

.font-28 {
  font-size: 28px;
  letter-spacing: -0.01em;
}

.font-30 {
  font-size: 30px;
  letter-spacing: -0.01em;
}

.font-33 {
  font-size: 33px;
  letter-spacing: -0.01em;
}

.font-34 {
  font-size: 34px;
  letter-spacing: -0.01em;
}

.font-38 {
  font-size: 38px;
  letter-spacing: -0.01em;
}

.font-40 {
  font-size: 40px;
  letter-spacing: -0.01em;
}

.font-49 {
  font-size: 49px;
  letter-spacing: -0.01em;
}

.font-70 {
  font-size: 70px;
  letter-spacing: -0.01em;
}

.m-auto {
  margin: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.m-20 {
  margin: 20px;
}

.m-30 {
  margin: 30px;
}

.m-40 {
  margin: 40px;
}

.p-0 {
  padding: 0;
}

.p-5 {
  padding: 5px;
}

.py-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.px-10 {
  padding-right: 10px;
  padding-left: 10px;
}

.px-15 {
  padding-right: 15px;
  padding-left: 15px;
}

.p-30 {
  padding: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pl-15 {
  padding-left: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.z-100 {
  z-index: 100;
}

.pointer:hover {
  cursor: pointer;
}

.cursor-default:hover {
  cursor: default;
}

.disabled-pointer:hover {
  cursor: not-allowed;
}

.hover-color-black:hover {
  color: #111 !important;
}

.rotate-180 {
  transform: rotate(180deg);
}

.opacity-40 {
  opacity: 40%;
}

.opacity-50 {
  opacity: 50%;
}

.opacity-60 {
  opacity: 60%;
}

.opacity-80 {
  opacity: 80%;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-underline {
  text-decoration: underline;
}

.min-h-80v {
  min-height: 80vh;
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.text-white {
  color: #fff;
}

.text-gray {
  color: gray;
}

.text-fire-red {
  /* color: #ff4b4b; */
  color: #d60000;
}

.text-dark-maroon {
  color: #820000;
}

.text-blue {
  color: #17142b;
}

.text-musk-orange {
  color: #ff8700;
}

.text-pale-yellow {
  color: #ffc91c;
}

.text-pink {
  color: #ff745b;
}

.text-mint-blue {
  color: #00fcfc;
}

.text-dark-blue {
  color: #121022;
}

.text-light-pink {
  color: #71bd6d;
}

.bg-fire-red {
  /* background: #ff4b4b; */
  background: #d60000;
}

.bg-dark-maroon {
  background: #820000;
}

.bg-musk-orange {
  background: #ff8700;
}

.bg-pale-yellow {
  background: #ffc91c;
}

.bg-pink {
  background: #ff745b;
}

.bg-mint-blue {
  background: #00fcfc;
}

.bg-dark-blue {
  background: #121022;
}

.bg-light-pink {
  background: #71bd6d;
}

.bg-white {
  background: #fff;
}

.bg-pale-yellow-20 {
  background: rgb(255, 135, 0, 20%);
}

.circle-25 {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.circle-10 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

/* ============= Common CSS ============= */

.scanner-bg {
  position: relative;
  background: url("../icons/scan.gif");
  background-repeat: no-repeat;
  background-position: bottom;
  width: 166px;
  height: 166px;
}

.ellipse-1 {
  background: url("../icons/Ellipse-1.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 166px;
  height: 166px;
}

.ellipse-2 {
  background: url("../icons/Ellipse-2.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 166px;
  height: 166px;
}

.ellipse-2>div {
  font-family: "Power Grotesk";
  font-weight: 500;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.viCyber-bg-img {
  background: url("../images/Light_burst_gradient.jpg");
  background-color: #120f22;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.viCyber-score-gradient {
  background: -webkit-linear-gradient(117.76deg, #00c9bd 10.88%, #f7ae0d 86.63%, #a60b08 113.94%);
  background: linear-gradient(117.76deg, #00c9bd 10.88%, #f7ae0d 86.63%, #a60b08 113.94%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-currency {
  background: -webkit-linear-gradient(131.02deg, #ffc91c -39.08%, #00fcfc 124.16%);
  background: linear-gradient(131.02deg, #ffc91c -39.08%, #00fcfc 124.16%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.custom-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffc91c !important;
  /* Change the text color to #FFC91C with !important */
}

.custom-tabs .ant-tabs-tab {
  color: #fff !important;

  /* Change the text color to #FFC91C with !important */
}

.custom-tabs .ant-tabs-ink-bar {
  background-color: #ffc91c !important;
  /* Change the color of the line below the active tab to #FFC91C */
}

.custom-tabs .ant-tabs-nav .ant-tabs-nav-list {
  border: none !important;
  /* Remove the border from the navigation bar */
}

@media screen and (max-width: 991px) {
  .d-flex-max-lg {
    display: flex;
    flex-direction: row;
  }

  .d-flex-col-max-lg {
    display: flex;
    flex-direction: column;
  }

  .justify-center-max-lg {
    justify-content: center;
  }
}

@media screen and (min-width: 1300px) {
  .responsive-side-border {
    border-right: 0.92px solid rgba(255, 255, 255, 20%);
  }
}

.progress-tag {
  border-radius: 54px;
  width: 117px;
  height: 37px;
}

.pass-button-tag {
  background: rgba(0, 252, 252, 10%);
  color: #00fcfc;
}

.fail-button-tag {
  background: rgba(255, 116, 91, 10%);
  color: #ff745b;
}

.divider-thin {
  border-width: thin;
  border-color: rgba(255, 255, 255, 10%);
}

.divider-thin::before,
.divider-thin::after {
  border-width: thin;
  border-color: rgba(255, 255, 255, 10%);
  border-block-start-color: rgba(255, 255, 255, 10%) !important;
}

.divider-thin .ant-divider-inner-text {
  color: #fff;
}

.divider-text {
  position: absolute;
  top: 0;
  left: 50%;
  margin-top: -17px;
  background-color: #232036;
  padding: 0 15px;
}

button,
.viCyber-btn,
.viCyber-btn-default {
  text-align: center;
}

/* ================ */

/* table */

.viCyber-table-transparent-wrapper .viCyber-table-transparent-tbody>tr>td.viCyber-table-transparent-cell-row-hover {
  background: transparent;
}

.viCyber-table-wrapper .viCyber-table,
.viCyber-table-wrapper .viCyber-table .viCyber-table-thead>tr>th,
.viCyber-table-wrapper .viCyber-table .viCyber-table-thead>tr>td,
.viCyber-table-transparent-wrapper .viCyber-table-transparent,
.viCyber-table-transparent-wrapper .viCyber-table-transparent .viCyber-table-transparent-thead>tr>th,
.viCyber-table-transparent-wrapper .viCyber-table-transparent .viCyber-table-transparent-thead>tr>td {
  background: transparent;
}

.viCyber-table-wrapper .viCyber-table table,
.viCyber-table-transparent-wrapper .viCyber-table-transparent table {
  border-collapse: separate;
  border-spacing: 0 15px;
}

.viCyber-table-wrapper .viCyber-table .viCyber-table-thead>tr>th,
.viCyber-table-wrapper .viCyber-table .viCyber-table-thead>tr>td,
.viCyber-table-transparent-wrapper .viCyber-table-transparent .viCyber-table-transparent-thead>tr>th,
.viCyber-table-transparent-wrapper .viCyber-table-transparent .viCyber-table-transparent-thead>tr>td {
  border-bottom: none;
  color: #fff;
  opacity: 30%;
  font-family: "Power Grotesk";
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.01em;
  text-align: left;
  padding: 10px;
}

.viCyber-table-transparent-wrapper .viCyber-table-transparent .viCyber-table-transparent-thead>tr>th,
.viCyber-table-transparent-wrapper .viCyber-table-transparent .viCyber-table-transparent-thead>tr>td {
  opacity: 40%;
}

.viCyber-table-wrapper .viCyber-table .viCyber-table-thead>tr>th:not(:last-child):not(.viCyber-table-selection-column):not(.viCyber-table-row-expand-icon-cell):not([colspan])::before,
.viCyber-table-transparent-wrapper .viCyber-table-transparent .viCyber-table-transparent-thead>tr>th:not(:last-child):not(.viCyber-table-transparent-selection-column):not(.viCyber-table-transparent-row-expand-icon-cell):not([colspan])::before {
  width: 0;
}

.viCyber-table-wrapper .viCyber-table .viCyber-table-tbody>tr {
  background: rgba(255, 255, 255, 5%);
  border-radius: 11px;
  color: #fff;
  font-family: "Power Grotesk";
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  text-transform: capitalize;
}

.viCyber-table-transparent-wrapper .viCyber-table-transparent .viCyber-table-transparent-tbody>tr {
  background: transparent;
  border-radius: 11px;
  color: #fff;
  font-family: "Power Grotesk";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  text-transform: capitalize;
}

.viCyber-table-wrapper .viCyber-table .viCyber-table-tbody>tr:hover,
.viCyber-table-wrapper .viCyber-table .viCyber-table-tbody>tr:hover>td {
  background: #444254;
}

.viCyber-table-transparent-wrapper .viCyber-table-transparent .viCyber-table-transparent-tbody>tr:hover,
.viCyber-table-transparent-wrapper .viCyber-table-transparent .viCyber-table-transparent-tbody>tr:hover>td,
.viCyber-table-transparent-wrapper .viCyber-table-transparent .viCyber-table-transparent-tbody>tr.viCyber-table-transparent-expanded-row:hover,
.viCyber-table-transparent-wrapper .viCyber-table-transparent .viCyber-table-transparent-tbody>tr.viCyber-table-transparent-expanded-row:hover>td {
  background: transparent;
}

.viCyber-table-wrapper .viCyber-table .viCyber-table-tbody>tr.viCyber-table-expanded-row:hover,
.viCyber-table-wrapper .viCyber-table .viCyber-table-tbody>tr.viCyber-table-expanded-row:hover>td {
  background: #232036;
}

.viCyber-table-transparent-wrapper .viCyber-table-transparent-tbody>tr>td {
  border-bottom: none;
}

.viCyber-table-transparent-wrapper .viCyber-table-transparent-tbody>tr:first-child>td {
  border-top: 2px solid rgba(255, 255, 255, 10%) !important;
}

.viCyber-table-wrapper .viCyber-table .viCyber-table-tbody>tr>td {
  border-bottom: none;
  padding: 30px;
}

.viCyber-table-wrapper .viCyber-table .viCyber-table-tbody>tr>td:first-child {
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
}

.viCyber-table-wrapper .viCyber-table .viCyber-table-tbody>tr>td:last-child {
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}

/* table -- */

/* predict table */

.viCyber-predict-table-wrapper .viCyber-predict-table,
.viCyber-predict-table-wrapper .viCyber-predict-table .viCyber-predict-table-thead>tr>th,
.viCyber-predict-table-wrapper .viCyber-predict-table .viCyber-predict-table-thead>tr>td,
.viCyber-predict-table-wrapper .viCyber-predict-table .viCyber-predict-table-tbody>tr>td.viCyber-predict-table-cell-fix-left,
.viCyber-predict-table-wrapper .viCyber-predict-table .viCyber-predict-table-tbody>tr:hover,
.viCyber-predict-table-wrapper .viCyber-predict-table .viCyber-predict-table-tbody>tr:hover>td,
.viCyber-predict-table-wrapper .viCyber-predict-table .viCyber-predict-table-tbody>tr:hover>td.viCyber-predict-table-cell-fix-left {
  background: #232036;
}

.viCyber-predict-table-wrapper .viCyber-predict-table .viCyber-predict-table-thead>tr>th::before {
  display: none;
}

.viCyber-predict-table-wrapper .viCyber-predict-table .viCyber-predict-table-thead>tr>th.viCyber-predict-table-cell-fix-left::before {
  display: block;
  top: 65% !important;
  height: 5em !important;
  background-color: rgba(255, 255, 255, 10%) !important;
}

.viCyber-predict-table-wrapper .viCyber-predict-table .viCyber-predict-table-tbody>tr>td.viCyber-predict-table-cell-fix-left {
  position: relative;
}

.viCyber-predict-table-wrapper .viCyber-predict-table .viCyber-predict-table-tbody>tr>td.viCyber-predict-table-cell-fix-left::before {
  content: "";
  position: absolute;
  width: 1px;
  inset-inline-end: 0;
  top: 0% !important;
  height: 4em !important;
  background-color: rgba(255, 255, 255, 10%) !important;
}

.viCyber-predict-table-wrapper .viCyber-predict-table-ping-left .viCyber-predict-table-cell-fix-left-last::after {
  border-left-width: thin;
  border-left-color: rgba(255, 255, 255, 10%);
  box-shadow: none;
}

.viCyber-predict-table-wrapper .viCyber-predict-table-thead>tr>th,
.viCyber-predict-table-wrapper .viCyber-predict-table-thead>tr>td,
.viCyber-predict-table-wrapper .viCyber-predict-table-tbody>tr>td {
  border-width: thin;
  border-color: rgba(255, 255, 255, 10%);
}

.viCyber-predict-table-wrapper .viCyber-predict-table-tbody>tr>td.viCyber-predict-table-cell input,
.viCyber-predict-table-wrapper .viCyber-predict-table-tbody>tr>td.viCyber-predict-table-cell .ant-input-affix-wrapper {
  background: transparent;
  width: 100%;
  border: none;
  box-shadow: none;
  color: rgba(255, 255, 255);
  font-family: "Space Grotesk";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.01em;
}

.viCyber-predict-table-wrapper .viCyber-predict-table .viCyber-predict-table-thead>tr>th {
  font-family: "Space Grotesk";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #ffc91c;
}

.viCyber-predict-table-wrapper .viCyber-predict-table .viCyber-predict-table-thead>tr>th:first-child,
.viCyber-predict-table-wrapper .viCyber-predict-table-tbody>tr>td.viCyber-predict-table-cell {
  padding: 0;
  border-bottom: none;
}

.viCyber-predict-table-wrapper .viCyber-predict-table .viCyber-predict-table-thead>tr>th:first-child>span,
.viCyber-predict-table-wrapper .viCyber-predict-table-tbody>tr>td.viCyber-predict-table-cell:first-child>span {
  display: block;
  width: 95%;
  padding: 16px 0px 16px 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 10%);
}

.viCyber-predict-table-wrapper .viCyber-predict-table .viCyber-predict-table-thead>tr>th:first-child>span {
  padding-top: 40px;
  padding-bottom: 40px;
}

.viCyber-predict-table-wrapper .viCyber-predict-table-tbody>tr>td.viCyber-predict-table-cell:nth-child(2) {
  width: 90%;
  float: right;
}

.viCyber-predict-table-wrapper .viCyber-predict-table-tbody>tr>td.viCyber-predict-table-cell>span {
  display: block;
  width: 100%;
  padding: 16px 0px 16px 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 10%);
}

.viCyber-predict-table-wrapper .viCyber-predict-table-tbody>tr>td.viCyber-predict-table-cell:not(:first-child)>span {
  padding: 6px 0 6px 6px;
}

.viCyber-predict-table-wrapper .viCyber-predict-table-tbody>tr>td.viCyber-predict-table-cell:not(:first-child)>span>span {
  display: block;
  width: 100%;
  padding: 10px 0 10px 10px;
  border-right: 1px solid rgba(255, 255, 255, 10%);
}

/* predict table -- */

/* predict benchmark/annual table */
.viCyber-predict-sub-table-wrapper .viCyber-predict-sub-table,
.viCyber-predict-sub-table-wrapper .viCyber-predict-sub-table .viCyber-predict-sub-table-thead>tr>th,
.viCyber-predict-sub-table-wrapper .viCyber-predict-sub-table .viCyber-predict-sub-table-thead>tr>td,
.viCyber-predict-sub-table-wrapper .viCyber-predict-sub-table .viCyber-predict-sub-table-tbody>tr>td,
.viCyber-predict-sub-table-wrapper .viCyber-predict-sub-table .viCyber-predict-sub-table-tbody>tr:hover>td {
  /* background: #232036; */
  background: rgba(0, 0, 0, 20%);
}

.viCyber-predict-sub-table-wrapper .viCyber-predict-sub-table .viCyber-predict-sub-table-thead>tr>th {
  background: linear-gradient(0deg, #ffffff, #ffffff);
  font-family: "Space Grotesk";
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.01em;
}

.viCyber-predict-sub-table-wrapper .viCyber-predict-sub-table .viCyber-predict-sub-table-thead>tr>th:first-child {
  background: linear-gradient(0deg, #ffc91c, #ffc91c);
  color: #17142b;
}

/* predict benchmark/annual table -- */

.viCyber-table-transparent-wrapper.table-no-header table>tbody>tr:first-child>td {
  border-top: none !important;
}

/* progress */
.viCyber-progress-bar {
  margin-bottom: 0;
}

/* progress -- */

/* expandable button */

.expandable-button,
.expandable-button>svg {
  width: 26px;
  height: 26px;
  transition: 0.3s all;
}

.expandable-button.expanded>svg {
  color: #ffc91c;
  transform: rotate(180deg);
}

/* expandable button -- */

/* skeleton */

.viCyber-skeleton.viCyber-skeleton-active .viCyber-skeleton-title,
.viCyber-skeleton.viCyber-skeleton-active .viCyber-skeleton-paragraph>li {
  background: linear-gradient(90deg, rgb(255 255 255 / 6%) 25%, rgb(255 255 255 / 15%) 37%, rgb(255 255 255 / 6%) 63%);
  background-size: 400% 100%;
  animation-name: css-dev-only-do-not-override-fpg3f5-ant-skeleton-loading;
  animation-duration: 1.4s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

/* skeleton -- */

/* upload */

.viCyber-upload-wrapper .viCyber-upload-list .viCyber-upload-list-item-container::before {
  display: block;
}

.viCyber-upload-wrapper .viCyber-upload-list::before,
.viCyber-upload-wrapper .viCyber-upload-list::after {
  display: none;
}

/* upload -- */

/* pagination */

.viCyber-pagination {
  margin-top: 10px;
}

.viCyber-pagination .viCyber-pagination-item {
  color: #fff;
  opacity: 40%;
}

.viCyber-pagination .viCyber-pagination-item.viCyber-pagination-item-active {
  border: none;
  background: transparent;
  opacity: 100%;
}

.viCyber-pagination-jump-next.viCyber-pagination-jump-next-custom-icon,
.viCyber-pagination-jump-prev.viCyber-pagination-jump-prev-custom-icon {
  position: relative;
}

.viCyber-pagination-jump-next.viCyber-pagination-jump-next-custom-icon::after,
.viCyber-pagination-jump-prev.viCyber-pagination-jump-prev-custom-icon::after {
  content: ". . .";
  color: #fff;
  opacity: 40%;
  margin-top: -5px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  left: 50%;
}

/* pagination -- */

.viCyber-search {
  height: 46px;
  width: calc(100% - 46px);
}

.viCyber-search .ant-input-wrapper.ant-input-group {
  display: flex;
}

.viCyber-search .ant-input-wrapper.ant-input-group>.ant-input-affix-wrapper>input::placeholder {
  font-family: "Space Grotesk";
  font-weight: 500;
  color: #17142b;
  font-size: 14px;
  letter-spacing: 1px;
  opacity: 60%;
}

.viCyber-search .ant-input-wrapper.ant-input-group>.ant-input-affix-wrapper>input {
  font-family: "Power Grotesk";
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.03em;
  color: #17142b;
}

.viCyber-search .ant-input-wrapper.ant-input-group>.ant-input-affix-wrapper {
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
}

.viCyber-search .ant-input-wrapper.ant-input-group>.ant-input-affix-wrapper,
.viCyber-search .ant-input-wrapper.ant-input-group>.ant-input-affix-wrapper:focus,
.viCyber-search .ant-input-wrapper.ant-input-group>.ant-input-affix-wrapper:hover {
  border: none;
}

.viCyber-search .ant-input-wrapper.ant-input-group>.ant-input-group-addon {
  border: none;
  z-index: 99;
  padding: 0;
}

.viCyber-search .ant-input-wrapper.ant-input-group>.ant-input-group-addon>button {
  box-shadow: none;
  border: none;
  width: 46px;
  height: 46px;
  border-radius: 0;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}

.viCyber-responsive-content {
  min-height: 100vh;
}

/* button */

.get-started-button {
  width: 150px;
  height: 48px;
  background: linear-gradient(131.02deg, #ffc91c -39.08%, #00fcfc 124.16%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 2.2058823108673096px 3.308823585510254px 9.926470756530762px 0px #00000040;
}

.gradient-button {
  background: linear-gradient(131.02deg, #ffc91c -39.08%, #00fcfc 124.16%);
}

.sync-btn {
  position: relative;
  text-decoration: underline;
  padding: 10px 15px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 10%);
}

.sync-btn::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 20px;
  padding: 3px;
  background: linear-gradient(117.76deg, #00c9bd 10.88%, #f7ae0d 86.63%, #a60b08 113.94%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.viCyber-btn {
  border-radius: 9px !important;
  transition: 0.3s all;
}

.viCyber-btn-default:not(:disabled):not(.viCyber-btn-disabled):hover {
  color: #111;
}

.viCyber-btn.viCyber-bordered-button {
  border: 2px solid #fff;
}

.viCyber-btn.viCyber-bordered-button:disabled,
.viCyber-btn.viCyber-filled-button:hover:disabled {
  color: rgba(255, 255, 255, 50%) !important;
}

.viCyber-btn.viCyber-bordered-button:hover {
  background-color: #fff !important;
  color: #17142b !important;
  border-color: #fff !important;
}

.viCyber-btn.viCyber-filled-button {
  border: 2px solid #fff !important;
  background-color: #fff;
  color: #17142b;
}

.viCyber-btn.viCyber-filled-button.viCyber-filled-button-pale-yellow {
  background: rgb(255, 201, 28) !important;
  border: 1px solid rgb(255, 201, 28) !important;
}

.viCyber-btn.viCyber-filled-button:hover {
  border: 2px solid #fff !important;
  color: #fff !important;
  background-color: transparent !important;
}

.viCyber-btn.viCyber-filled-button-no-hover:hover {
  background-color: #fff !important;
  color: #17142b !important;
}

.viCyber-spinner .viCyber-spinner-text,
.viCyber-spinner-nested-loading>div>.viCyber-spinner .viCyber-spinner-text {
  font-size: 22px;
}

.viCyber-spinner.all-cover,
.viCyber-spinner-nested-loading>div>.viCyber-spinner.all-cover {
  max-height: 100%;
}

.viCyber-message .viCyber-message-notice .viCyber-message-notice-content {
  border-radius: 47px;
}

.viCyber-message .viCyber-message-notice .viCyber-message-custom-content {
  display: flex;
  align-items: center;
}

.viCyber-message .viCyber-message-notice.viCyber-message-notice-success .viCyber-message-notice-content {
  background: linear-gradient(111.56deg, #ffc91c -19.54%, #00fcfc 81.55%);
}

.viCyber-message .viCyber-message-notice .viCyber-message-notice-content .viCyber-message-custom-content>span+span {
  font-size: 20px;
  font-family: "Space Grotesk";
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.01em;
 /* text-transform: capitalize;*/
}

.viCyber-message .viCyber-message-notice.viCyber-message-notice-success .viCyber-message-notice-content .viCyber-message-success>span+span {
  color: #17142b;
}

.viCyber-message .viCyber-message-notice.viCyber-message-notice-error .viCyber-message-notice-content .viCyber-message-error>span+span {
  color: #fff;
}

.viCyber-message .viCyber-message-notice.viCyber-message-notice-error .viCyber-message-notice-content {
  background: #d60000;
}

.viCyber-message .viCyber-message-notice .viCyber-message-success>.anticon {
  color: #17142b;
}

.viCyber-message .viCyber-message-notice .viCyber-message-error>.anticon {
  color: #fff;
}

.viCyber-message .viCyber-message-notice .viCyber-message-success>.anticon>svg,
.viCyber-message .viCyber-message-notice .viCyber-message-error>.anticon>svg {
  height: 30px;
  width: 30px;
}

/* Input Fix estimation */

.viCyber-input-estimation-group-wrapper>.viCyber-input-estimation-wrapper>input {
  font-family: "Space Grotesk";
  padding: 20px;
  border-right: none;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.viCyber-input-estimation-group-wrapper>.viCyber-input-estimation-wrapper>input:focus {
  border-color: #d9d9d9;
  box-shadow: none;
}

.viCyber-input-estimation-group-wrapper>.viCyber-input-estimation-wrapper>input::placeholder {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

.viCyber-input-estimation-group-wrapper>.viCyber-input-estimation-wrapper>.viCyber-input-estimation-group-addon {
  background-color: white;
  font-family: "Space Grotesk";
  padding: 0 20px;
  font-size: 20px;
  color: rgb(23, 20, 43);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* Input Fix estimation -- */

/* modal */
.viCyber-modal-mask {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(4px);
}

.viCyber-modal .viCyber-modal-content {
  position: relative;
  background: #17142b;
  padding: 35px;
}

.viCyber-modal .viCyber-modal-content::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 10px;
  padding: 3px;
  background: linear-gradient(117.76deg, #00c9bd 10.88%, #f7ae0d 86.63%, #a60b08 113.94%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.viCyber-modal .viCyber-modal-content .viCyber-modal-close-x svg {
  fill: #fff;
}

.viCyber-modal .viCyber-modal-content .viCyber-modal-header {
  background: transparent;
  font-family: "Power Grotesk";
  font-weight: 700;
  font-size: 22px;
  color: #fff;
  line-height: 22px;
}

.viCyber-modal .viCyber-modal-content .viCyber-modal-header .viCyber-modal-title {
  font-family: "Power Grotesk";
  font-weight: 700;
  font-size: 22px;
  color: #fff;
  line-height: 22px;
  color: #fff;
  margin-bottom: 20px;
}

.viCyber-modal .viCyber-modal-content .viCyber-modal-body {
  font-family: "Space Grotesk";
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  line-height: 22px;
  color: #fff;
  margin-bottom: 30px;
}

.viCyber-modal .viCyber-modal-content .viCyber-modal-footer>button+button {
  margin-inline-start: 15px !important;
}

.viCyber-modal .viCyber-modal-content .viCyber-modal-footer>button:first-child {
  width: 184px;
  height: 59px;
  background: rgba(217, 217, 217, 10%);
  font-family: "Space Grotesk";
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
}

.viCyber-modal .viCyber-modal-content .viCyber-modal-footer>button:last-child {
  width: 184px;
  height: 59px;
  background: #fff;
  font-family: "Space Grotesk";
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #000;
}

/* modal -- */

/* admin tabs */
.viCyber-tabs-group {
  background: rgba(255, 255, 255, 10%);
  border: none;
  border-radius: 11px;
}

.viCyber-tab-button-wrapper:first-child {
  border: none;
}

.viCyber-tabs-group label {
  background: transparent;
  border: none;
}

.viCyber-tab-button-wrapper.viCyber-tab-button-wrapper-checked {
  background: linear-gradient(131.02deg, #ffc91c -39.08%, #00fcfc 124.16%);
  border-radius: 11px;
  color: #17142b;
}

.viCyber-tab-button-wrapper.viCyber-tab-button-wrapper-checked:hover {
  color: #17142b;
}

.viCyber-tab-button-wrapper:hover {
  color: #00fcfc;
}

.viCyber-tab-button-wrapper:not(:first-child)::before {
  display: none;
}

/* admin tabs -- */

/* date-picker */
.viCyber-date-picker {
  background: transparent;
  height: 55px;
  border-radius: 9px;
}

.viCyber-date-picker-focused {
  border-color: white;
}

.viCyber-date-picker .viCyber-date-picker-input input {
  color: #fff;
  font-family: "Space Grotesk";
  font-size: 18px;
  font-weight: 500;
}

.viCyber-date-picker .viCyber-date-picker-input .viCyber-date-picker-suffix,
.viCyber-date-picker .viCyber-date-picker-input input::placeholder {
  color: white;
}

.viCyber-date-picker .viCyber-date-picker-input .viCyber-date-picker-suffix .anticon-calendar svg {
  font-size: 22px;
}

.viCyber-date-picker .viCyber-date-picker-input .viCyber-date-picker-clear {
  background: black;
  border-radius: 48px;
  right: -1px;
  top: 52%;
}

.viCyber-date-picker .viCyber-date-picker-input .viCyber-date-picker-clear .anticon-close-circle svg {
  font-size: 25px;
  fill: white;
}

/* date-picker -- */

/* nav tabs */
.viCyber-nav-tabs .viCyber-nav-tabs-tab.viCyber-nav-tabs-tab-disabled {
  cursor: default;
}

.viCyber-nav-tabs-top>.viCyber-nav-tabs-nav::before {
  border-bottom: none;
}

.viCyber-nav-tabs .viCyber-nav-tabs-nav .viCyber-nav-tabs-nav-list .viCyber-nav-tabs-tab .viCyber-nav-tabs-tab-btn {
  font-family: "Space Grotesk";
  font-size: 22px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: -0.01em;
  color: #fff;
}

.viCyber-nav-tabs .viCyber-nav-tabs-nav .viCyber-nav-tabs-nav-list .viCyber-nav-tabs-tab.viCyber-nav-tabs-tab-active .viCyber-nav-tabs-tab-btn {
  font-weight: 700;
  color: #ffc91c;
}

.viCyber-nav-tabs .viCyber-nav-tabs-tab+.viCyber-nav-tabs-tab {
  margin: 0 0 0 40px;
}

.viCyber-nav-tabs .viCyber-nav-tabs-nav .viCyber-nav-tabs-ink-bar {
  height: 4px;
  border-radius: 12px;
  background: #ffc91c;
}

/* nav tabs -- */

/* steps */
.viCyber-steps.viCyber-steps-dot .viCyber-steps-item-icon .viCyber-steps-icon-dot {
  width: 21px;
  height: 21px;
}

.viCyber-steps .viCyber-steps-item-wait .viCyber-steps-item-icon>.viCyber-steps-icon .viCyber-steps-icon-dot {
  background: rgba(255, 255, 255, 20%);
}

.viCyber-steps .viCyber-steps-item-process .viCyber-steps-item-icon>.viCyber-steps-icon .viCyber-steps-icon-dot,
.viCyber-steps .viCyber-steps-item-finish>.viCyber-steps-item-container>.viCyber-steps-item-tail::after,
.viCyber-steps .viCyber-steps-item-finish .viCyber-steps-item-icon>.viCyber-steps-icon .viCyber-steps-icon-dot {
  background: #00fcfc;
}

.viCyber-steps .viCyber-steps-item-finish>.viCyber-steps-item-container>.viCyber-steps-item-tail::after {
  width: calc(100% - 38px);
  margin-inline-start: 26px;
}

.viCyber-steps.viCyber-steps-dot .viCyber-steps-item-tail {
  top: 9px;
}

.viCyber-steps .viCyber-steps-item-wait>.viCyber-steps-item-container>.viCyber-steps-item-tail::after,
.viCyber-steps .viCyber-steps-item-process>.viCyber-steps-item-container>.viCyber-steps-item-tail::after {
  background-color: rgba(255, 255, 255, 20%);
  width: calc(100% - 43px);
  height: 3px;
  margin-inline-start: 28px;
}

.viCyber-steps .viCyber-steps-item-process>.viCyber-steps-item-container>.viCyber-steps-item-content>.viCyber-steps-item-title,
.viCyber-steps .viCyber-steps-item-wait>.viCyber-steps-item-container>.viCyber-steps-item-content>.viCyber-steps-item-title,
.viCyber-steps .viCyber-steps-item-finish>.viCyber-steps-item-container>.viCyber-steps-item-content>.viCyber-steps-item-title {
  font-family: "Space Grotesk";
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: #fff;
  margin-top: 10px;
}

.viCyber-steps .viCyber-steps-item-wait>.viCyber-steps-item-container>.viCyber-steps-item-content>.viCyber-steps-item-title {
  opacity: 30%;
}

@media screen and (max-width: 991px) {
  .viCyber-steps-horizontal {
    padding-top: 10px;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .viCyber-steps.viCyber-steps-vertical.viCyber-steps-dot .viCyber-steps-item>.viCyber-steps-item-container>.viCyber-steps-item-tail::after {
    margin-inline-start: 10px;
  }

  .viCyber-steps.viCyber-steps-vertical.viCyber-steps-dot .viCyber-steps-item>.viCyber-steps-item-container>.viCyber-steps-item-tail {
    top: 19px;
  }
}

/* steps -- */

/* switch */
.viCyber-switch,
.viCyber-switch:hover:not(.viCyber-switch-disabled) {
  background: transparent;
  border: 1px solid white;
}

.viCyber-switch.viCyber-switch-checked,
.viCyber-switch.viCyber-switch-checked:hover:not(.viCyber-switch-disabled) {
  background: white;
  border: 1px solid white;
}

.viCyber-switch .viCyber-switch-handle {
  top: 1px;
}

.viCyber-switch.viCyber-switch-checked .viCyber-switch-handle::before {
  background: green;
}

.viCyber-switch .viCyber-switch-inner .viCyber-switch-inner-checked {
  color: #000000;
}

/* switch -- */

.viCyber-collapse>.viCyber-collapse-item>.viCyber-collapse-header {
  padding-left: 0;
  padding-right: 0;
  display: flex;
  align-items: center;
  color: transparent;
}

.viCyber-collapse .viCyber-collapse-content>.viCyber-collapse-content-box,
.viCyber-collapse-ghost>.viCyber-collapse-item>.viCyber-collapse-content>.viCyber-collapse-content-box {
  padding: 0;
  padding-block: 0;
}

.viCyber-spinner {
  width: 63px !important;
  height: 63px !important;
  top: 40% !important;
  inset-inline-start: calc(50% - 18px) !important;
  animation: spin 10s linear infinite;
}

.viCyber-spinner+.ant-spin-text {
  font-family: "Power Grotesk";
  font-weight: 400;
  font-size: 20px !important;
  line-height: 20px;
  letter-spacing: 0.03em;
}

.spin {
  animation-name: spin;
  animation-duration: 10s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

.viCyber-loader {
  width: 100%;
  height: 85%;
  display: flex;
}

@keyframes spin {
  100% {
    transform: rotate(5turn);
  }
}

.viCyber.viCyber-root .viCyber-item .viCyber-title-content {
  margin-inline-start: 5px;
}

/* ========================= */

@media screen and (max-width: 991px) {
  .viCyber-responsive-content {
    min-height: 80vh;
  }

  .viCyber.viCyber-root .viCyber-item .viCyber-title-content {
    font-size: 20px;
  }

  .p-15-max-lg {
    padding: 15px;
  }

  .all-center-start-lg {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  img.helper-icon {
    width: 45px;
    height: 45px;
  }

  img.overview-icon {
    width: 55px;
    height: 55px;
  }

  .viCyber-card .viCyber-card-head,
  .viCyber-card-bordered .viCyber-card-bordered-head {
    font-size: 20px;
  }
}

@media only screen and (max-width: 576px) {
  .viCyber-tabs-group .viCyber-tab-button-wrapper {
    font-size: 12px;
  }

  .justify-start-sm {
    justify-content: flex-start;
  }

  .viCyber-modal .viCyber-modal-content .viCyber-modal-footer {
    display: flex;
  }

  .viCyber-modal .viCyber-modal-content .viCyber-modal-footer>button,
  .viCyber-modal .viCyber-modal-content .viCyber-modal-footer>button:first-child,
  .viCyber-modal .viCyber-modal-content .viCyber-modal-footer>button:last-child {
    width: 130px;
  }
}

/* Small devices (portrait tablets and large phones, 576px and up) */
@media only screen and (min-width: 576px) {
  .viCyber-tabs-group .viCyber-tab-button-wrapper {
    font-size: 14px;
  }

  .ant-form label {
    font-size: 14px;
    letter-spacing: -0.01em;
  }

  .font-12-sm {
    font-size: 12px;
    letter-spacing: -0.01em;
  }

  .font-14-sm {
    font-size: 14px;
    letter-spacing: -0.01em;
  }

  .font-16-sm {
    font-size: 16px;
    letter-spacing: -0.01em;
  }

  .font-18-sm {
    font-size: 18px;
    letter-spacing: -0.01em;
  }

  .font-20-sm {
    font-size: 20px;
    letter-spacing: -0.01em;
  }

  .font-22-sm {
    font-size: 22px;
    letter-spacing: -0.01em;
  }

  .font-24-sm {
    font-size: 24px;
    letter-spacing: -0.01em;
  }

  .font-26-sm {
    font-size: 26px;
    letter-spacing: -0.01em;
  }

  .font-28-sm {
    font-size: 28px;
    letter-spacing: -0.01em;
  }

  .font-30-sm {
    font-size: 30px;
    letter-spacing: -0.01em;
  }

  .font-32-sm {
    font-size: 32px;
    letter-spacing: -0.01em;
  }

  .font-34-sm {
    font-size: 34px;
    letter-spacing: -0.01em;
  }

  .font-36-sm {
    font-size: 36px;
    letter-spacing: -0.01em;
  }

  .font-38-sm {
    font-size: 38px;
    letter-spacing: -0.01em;
  }

  .font-40-sm {
    font-size: 40px;
    letter-spacing: -0.01em;
  }

  .font-42-sm {
    font-size: 42px;
    letter-spacing: -0.01em;
  }

  .font-44-sm {
    font-size: 44px;
    letter-spacing: -0.01em;
  }

  .font-70-sm {
    font-size: 70px;
    letter-spacing: -0.01em;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .viCyber-tabs-group .viCyber-tab-button-wrapper {
    font-size: 14px;
    letter-spacing: -0.01em;
  }

  .ant-form label {
    font-size: 16px;
    letter-spacing: -0.01em;
  }

  .font-12-md {
    font-size: 12px;
    letter-spacing: -0.01em;
  }

  .font-14-md {
    font-size: 14px;
    letter-spacing: -0.01em;
  }

  .font-16-md {
    font-size: 16px;
    letter-spacing: -0.01em;
  }

  .font-18-md {
    font-size: 18px;
    letter-spacing: -0.01em;
  }

  .font-20-md {
    font-size: 20px;
    letter-spacing: -0.01em;
  }

  .font-22-md {
    font-size: 22px;
    letter-spacing: -0.01em;
  }

  .font-24-md {
    font-size: 24px;
    letter-spacing: -0.01em;
  }

  .font-26-md {
    font-size: 26px;
    letter-spacing: -0.01em;
  }

  .font-28-md {
    font-size: 28px;
    letter-spacing: -0.01em;
  }

  .font-30-md {
    font-size: 30px;
    letter-spacing: -0.01em;
  }

  .font-32-md {
    font-size: 32px;
    letter-spacing: -0.01em;
  }

  .font-34-md {
    font-size: 34px;
    letter-spacing: -0.01em;
  }

  .font-36-md {
    font-size: 36px;
    letter-spacing: -0.01em;
  }

  .font-38-md {
    font-size: 38px;
    letter-spacing: -0.01em;
  }

  .font-40-md {
    font-size: 40px;
    letter-spacing: -0.01em;
  }

  .font-42-md {
    font-size: 42px;
    letter-spacing: -0.01em;
  }

  .font-44-md {
    font-size: 44px;
    letter-spacing: -0.01em;
  }

  .font-70-md {
    font-size: 70px;
    letter-spacing: -0.01em;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .viCyber.viCyber-root .viCyber-item .viCyber-item-icon {
    width: 40px;
    height: 40px;
  }

  .viCyber.viCyber-root .viCyber-item .viCyber-title-content {
    font-size: 18px;
  }

  .viCyber-tabs-group .viCyber-tab-button-wrapper {
    font-size: 12px;
    letter-spacing: -0.01em;
  }

  .ant-form label {
    font-size: 16px;
    letter-spacing: -0.01em;
  }

  img.helper-icon {
    width: 35px;
    height: 35px;
  }

  img.overview-icon {
    width: 45px;
    height: 45px;
  }

  .viCyber-card .viCyber-card-head,
  .viCyber-card-bordered .viCyber-card-bordered-head {
    font-size: 18px;
  }

  .justify-start-lg {
    justify-content: flex-start;
  }

  .font-12-lg {
    font-size: 12px;
    letter-spacing: -0.01em;
  }

  .font-14-lg {
    font-size: 14px;
    letter-spacing: -0.01em;
  }

  .font-16-lg {
    font-size: 16px;
    letter-spacing: -0.01em;
  }

  .font-18-lg {
    font-size: 18px;
    letter-spacing: -0.01em;
  }

  .font-20-lg {
    font-size: 20px;
    letter-spacing: -0.01em;
  }

  .font-22-lg {
    font-size: 22px;
    letter-spacing: -0.01em;
  }

  .font-24-lg {
    font-size: 24px;
    letter-spacing: -0.01em;
  }

  .font-26-lg {
    font-size: 26px;
    letter-spacing: -0.01em;
  }

  .font-28-lg {
    font-size: 28px;
    letter-spacing: -0.01em;
  }

  .font-30-lg {
    font-size: 30px;
    letter-spacing: -0.01em;
  }

  .font-32-lg {
    font-size: 32px;
    letter-spacing: -0.01em;
  }

  .font-34-lg {
    font-size: 34px;
    letter-spacing: -0.01em;
  }

  .font-36-lg {
    font-size: 36px;
    letter-spacing: -0.01em;
  }

  .font-38-lg {
    font-size: 38px;
    letter-spacing: -0.01em;
  }

  .font-40-lg {
    font-size: 40px;
    letter-spacing: -0.01em;
  }

  .font-42-lg {
    font-size: 42px;
    letter-spacing: -0.01em;
  }

  .font-44-lg {
    font-size: 44px;
    letter-spacing: -0.01em;
  }

  .font-70-lg {
    font-size: 70px;
    letter-spacing: -0.01em;
  }
}

/* Extra large devices (large laptops and desktops, 1300px and up)  - xl */
@media only screen and (min-width: 1300px) {
  .viCyber.viCyber-root .viCyber-item .viCyber-item-icon {
    width: 45px;
    height: 45px;
  }

  .viCyber.viCyber-root .viCyber-item .viCyber-title-content {
    font-size: 20px;
  }

  .viCyber-tabs-group .viCyber-tab-button-wrapper {
    font-size: 14px;
    letter-spacing: -0.01em;
  }

  .ant-form label {
    font-size: 18px;
    letter-spacing: -0.01em;
  }

  img.helper-icon {
    width: 45px;
    height: 45px;
  }

  img.overview-icon {
    width: 45px;
    height: 45px;
  }

  .viCyber-card .viCyber-card-head,
  .viCyber-card-bordered .viCyber-card-bordered-head {
    font-size: 18px;
  }

  .font-12-xl {
    font-size: 12px;
    letter-spacing: -0.01em;
  }

  .font-14-xl {
    font-size: 14px;
    letter-spacing: -0.01em;
  }

  .font-16-xl {
    font-size: 16px;
    letter-spacing: -0.01em;
  }

  .font-18-xl {
    font-size: 18px;
    letter-spacing: -0.01em;
  }

  .font-20-xl {
    font-size: 20px;
    letter-spacing: -0.01em;
  }

  .font-22-xl {
    font-size: 22px;
    letter-spacing: -0.01em;
  }

  .font-24-xl {
    font-size: 24px;
    letter-spacing: -0.01em;
  }

  .font-26-xl {
    font-size: 26px;
    letter-spacing: -0.01em;
  }

  .font-28-xl {
    font-size: 28px;
    letter-spacing: -0.01em;
  }

  .font-30-xl {
    font-size: 30px;
    letter-spacing: -0.01em;
  }

  .font-32-xl {
    font-size: 32px;
    letter-spacing: -0.01em;
  }

  .font-34-xl {
    font-size: 34px;
    letter-spacing: -0.01em;
  }

  .font-36-xl {
    font-size: 36px;
    letter-spacing: -0.01em;
  }

  .font-38-xl {
    font-size: 38px;
    letter-spacing: -0.01em;
  }

  .font-40-xl {
    font-size: 40px;
    letter-spacing: -0.01em;
  }

  .font-42-xl {
    font-size: 42px;
    letter-spacing: -0.01em;
  }

  .font-44-xl {
    font-size: 44px;
    letter-spacing: -0.01em;
  }

  .font-70-xl {
    font-size: 70px;
    letter-spacing: -0.01em;
  }
}

@media only screen and (min-width: 1600px) {
  .viCyber.viCyber-root .viCyber-item .viCyber-item-icon {
    width: 54px;
    height: 54px;
  }

  .viCyber.viCyber-root .viCyber-item .viCyber-title-content {
    font-size: 22px;
  }

  .viCyber-tabs-group .viCyber-tab-button-wrapper {
    font-size: 16px;
    letter-spacing: -0.01em;
  }

  .ant-form label {
    font-size: 22px;
    letter-spacing: -0.01em;
  }

  img.helper-icon {
    width: 55px;
    height: 55px;
  }

  img.overview-icon {
    width: 75px;
    height: 75px;
  }

  .viCyber-card .viCyber-card-head,
  .viCyber-card-bordered .viCyber-card-bordered-head {
    font-size: 20px;
  }

  .font-12-xxl {
    font-size: 12px;
    letter-spacing: -0.01em;
  }

  .font-14-xxl {
    font-size: 14px;
    letter-spacing: -0.01em;
  }

  .font-16-xxl {
    font-size: 16px;
    letter-spacing: -0.01em;
  }

  .font-18-xxl {
    font-size: 18px;
    letter-spacing: -0.01em;
  }

  .font-20-xxl {
    font-size: 20px;
    letter-spacing: -0.01em;
  }

  .font-22-xxl {
    font-size: 22px;
    letter-spacing: -0.01em;
  }

  .font-24-xxl {
    font-size: 24px;
    letter-spacing: -0.01em;
  }

  .font-26-xxl {
    font-size: 26px;
    letter-spacing: -0.01em;
  }

  .font-28-xxl {
    font-size: 28px;
    letter-spacing: -0.01em;
  }

  .font-30-xxl {
    font-size: 30px;
    letter-spacing: -0.01em;
  }

  .font-32-xxl {
    font-size: 32px;
    letter-spacing: -0.01em;
  }

  .font-34-xxl {
    font-size: 34px;
    letter-spacing: -0.01em;
  }

  .font-36-xxl {
    font-size: 36px;
    letter-spacing: -0.01em;
  }

  .font-38-xxl {
    font-size: 38px;
    letter-spacing: -0.01em;
  }

  .font-40-xxl {
    font-size: 40px;
    letter-spacing: -0.01em;
  }

  .font-42-xxl {
    font-size: 42px;
    letter-spacing: -0.01em;
  }

  .font-44-xxl {
    font-size: 44px;
    letter-spacing: -0.01em;
  }

  .font-49-xxl {
    font-size: 49px;
    letter-spacing: -0.01em;
  }

  .font-70-xxl {
    font-size: 70px;
    letter-spacing: -0.01em;
  }
}

@media only screen and (min-width: 1900px) {
  .viCyber.viCyber-root .viCyber-item .viCyber-item-icon {
    width: 54px;
    height: 54px;
  }

  .viCyber.viCyber-root .viCyber-item .viCyber-title-content {
    font-size: 22px;
  }

  .viCyber-tabs-group .viCyber-tab-button-wrapper {
    font-size: 16px;
    letter-spacing: -0.01em;
  }

  .ant-form label {
    font-size: 22px;
    letter-spacing: -0.01em;
  }

  img.helper-icon {
    width: 55px;
    height: 55px;
  }

  img.overview-icon {
    width: 75px;
    height: 75px;
  }

  .viCyber-card .viCyber-card-head,
  .viCyber-card-bordered .viCyber-card-bordered-head {
    font-size: 20px;
  }

  .font-12-xxl {
    font-size: 10px;
    letter-spacing: -0.01em;
  }

  .font-14-xxl {
    font-size: 12px;
    letter-spacing: -0.01em;
  }

  .font-16-xxl {
    font-size: 14px;
    letter-spacing: -0.01em;
  }

  .font-18-xxl {
    font-size: 16px;
    letter-spacing: -0.01em;
  }

  .font-20-xxl {
    font-size: 18px;
    letter-spacing: -0.01em;
  }

  .font-22-xxl {
    font-size: 20px;
    letter-spacing: -0.01em;
  }

  .font-24-xxl {
    font-size: 22px;
    letter-spacing: -0.01em;
  }

  .font-26-xxl {
    font-size: 24px;
    letter-spacing: -0.01em;
  }

  .font-28-xxl {
    font-size: 26px;
    letter-spacing: -0.01em;
  }

  .font-30-xxl {
    font-size: 28px;
    letter-spacing: -0.01em;
  }

  .font-32-xxl {
    font-size: 30px;
    letter-spacing: -0.01em;
  }

  .font-34-xxl {
    font-size: 32px;
    letter-spacing: -0.01em;
  }

  .font-36-xxl {
    font-size: 34px;
    letter-spacing: -0.01em;
  }

  .font-38-xxl {
    font-size: 36px;
    letter-spacing: -0.01em;
  }

  .font-40-xxl {
    font-size: 38px;
    letter-spacing: -0.01em;
  }

  .font-42-xxl {
    font-size: 40px;
    letter-spacing: -0.01em;
  }

  .font-44-xxl {
    font-size: 42px;
    letter-spacing: -0.01em;
  }

  .font-49-xxl {
    font-size: 46px;
    letter-spacing: -0.01em;
  }

  .font-70-xxl {
    font-size: 66px;
    letter-spacing: -0.01em;
  }
}

/* ========================= */

.viCyber-card-full-body .viCyber-card-body {
  height: calc(100% - 56px);
}

.contact-input::placeholder,
.phone-input::placeholder {
  color: white;
}

#primaryCountryCode_help .ant-form-item-explain-error,
#primaryPhone_help .ant-form-item-explain-error,
#businessCountryCode_help .ant-form-item-explain-error,
#businessPhone_help .ant-form-item-explain-error {
  position: absolute;
  top: 133%;
  width: 163px;
}

#primaryCountryCode_help .ant-form-item-explain-error,
#businessCountryCode_help .ant-form-item-explain-error {
  left: -25%;
}

#primaryPhone_help .ant-form-item-explain-error,
#businessPhone_help .ant-form-item-explain-error {
  left: 10%;
}

.phone-code-select.ant-select .ant-select-arrow {
  color: #fff;
}

.phone-code-select.ant-select .ant-select-clear {
  background: transparent;
}

.phone-code-select.ant-select .ant-select-clear>span,
.phone-code-select.ant-select .ant-select-selection-placeholder {
  color: white;
}

.app-layout {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.ant-layout-footer {
  margin-top: auto;
}

.logo {
  max-width: 100%;
  height: auto;
}

.user-profile {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 24px;
}

.user-profile span {
  margin-left: 8px;
  color: #fff;
}

.signup-form label {
  color: #fff !important;
}

.text-white {
  color: #ffffff;
}

.text-gradient {
  color: linear-gradient(131.02deg, #ffc91c -39.08%, #00fcfc 124.16%);
}

.text-dark {
  color: #000000;
}

.text-dark-1 {
  color: #111;
}

.text-link {
  color: #ffffff;
  text-decoration: underline;
}

.text-link:hover {
  text-decoration: none;
  color: #ffffff;
}

.ant-form-item.global-label-white .ant-form-item-label>label {
  color: #fff;
}

.ant-checkbox-wrapper {
  color: #fff;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #fff;
  color: #fff;
}

.ant-radio-disabled .ant-radio-inner {
  background-color: #fff;
  color: #fff;
}

.checkbox-white .ant-checkbox.ant-checkbox+span {
  color: #dcdada;
}

.checkbox-disabled-white .ant-checkbox-disabled.ant-checkbox+span {
  color: #dcdada;
}

.ant-disabled-white .ant-radio-disabled .ant-radio-inner {
  background-color: #fff;
}

.ant-btn-dashed.ant-btn-disabled {
  background-color: #fff;
  color: #fff;
}

.ant-btn-dashed:disabled {
  color: #fff;
}

.ant-picker.ant-picker-disabled {
  background-color: #fff;
}

.ant-full-space .ant-space-item {
  width: 100%;
}

.ant-popover {
  z-index: 999999;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-checkbox-custom.ant-checkbox-wrapper {
  color: #000000;
}

.ant-form-item.predict-input-label .ant-form-item-label>label {
  height: 120px;
}

.custom-sorter-color.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: yellow !important;
}

.phone-code-select .ant-select-selector {
  border-color: none !important;
  box-shadow: none !important;
  border: none !important;
  background: transparent !important;
  color: #fff !important;
}

.phone-number-card .ant-card-body {
  padding: 7px;
  margin: 0;
}

.custom-steps {
  .ant-steps-item-title {
    color: white !important;
  }
}

.viCyber-table-transparent-wrapper .viCyber-table-transparent-footer {
  background: transparent;
  color: #ffffff;
}

.viCyber-table-transparent-wrapper td.viCyber-table-transparent-column-sort {
  background: transparent;
}

.viCyber-table-transparent-column-sorter-inner {
  color: #ffffff;
}