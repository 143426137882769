/* ==================================== */
/* ========== Power Grotesk =========== */
/* ==================================== */

@font-face {
    font-family: 'Power Grotesk';
    font-style : normal;
    font-weight: 200;
    src: url("../fonts/power/PowerGrotesk-UltraLight.otf");
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Power Grotesk';
    font-style : italic;
    font-weight: 200;
    src: url("../fonts/power/PowerGrotesk-UltraLightItalic.otf");
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Power Grotesk';
    font-style : normal;
    font-weight: 300;
    src: url("../fonts/power/PowerGrotesk-Light.otf");
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Power Grotesk';
    font-style : italic;
    font-weight: 300;
    src: url("../fonts/power/PowerGrotesk-LightItalic.otf");
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Power Grotesk';
    font-style : normal;
    font-weight: 400;
    src: url("../fonts/power/PowerGrotesk-Regular.otf");
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Power Grotesk';
    font-style : italic;
    font-weight: 400;
    src: url("../fonts/power/PowerGrotesk-Italic.otf");
    font-display: swap;
  }
  
  
  @font-face {
    font-family: 'Power Grotesk';
    font-style : normal;
    font-weight: 500;
    src: url("../fonts/power/PowerGrotesk-Medium.otf");
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Power Grotesk';
    font-style : italic;
    font-weight: 500;
    src: url("../fonts/power/PowerGrotesk-MediumItalic.otf");
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Power Grotesk';
    font-style : normal;
    font-weight: 600;
    src: url("../fonts/power/PowerGrotesk-Black.otf");
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Power Grotesk';
    font-style : italic;
    font-weight: 600;
    src: url("../fonts/power/PowerGrotesk-BlackItalic.otf");
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Power Grotesk';
    font-style : normal;
    font-weight: 700;
    src: url("../fonts/power/PowerGrotesk-Bold.otf");
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Power Grotesk';
    font-style : italic;
    font-weight: 700;
    src: url("../fonts/power/PowerGrotesk-BoldItalic.otf");
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Power Grotesk';
    font-style : normal;
    font-weight: 800;
    src: url("../fonts/power/PowerGrotesk-Heavy.otf");
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Power Grotesk';
    font-style : italic;
    font-weight: 800;
    src: url("../fonts/power/PowerGrotesk-HeavyItalic.otf");
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Power Grotesk';
    font-style : normal;
    font-weight: 900;
    src: url("../fonts/power/PowerGrotesk-UltraBold.otf");
    font-display: swap;
  }

  @font-face {
    font-family: 'Power Grotesk';
    font-style : italic;
    font-weight: 900;
    src: url("../fonts/power/PowerGrotesk-UltraBoldItalic.otf");
    font-display: swap;
  }
  
  
  /* ==================================== */
  /* ========== Space Grotesk =========== */
  /* ==================================== */
  
  
  
  @font-face {
    font-family: 'Space Grotesk';
    font-style : normal;
    font-weight: 300;
    src: url("../fonts/space/SpaceGrotesk-Light.ttf");
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Space Grotesk';
    font-style : normal;
    font-weight: 400;
    src: url("../fonts/space/SpaceGrotesk-Regular.ttf");
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Space Grotesk';
    font-style : normal;
    font-weight: 500;
    src: url("../fonts/space/SpaceGrotesk-Medium.ttf");
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Space Grotesk';
    font-style : normal;
    font-weight: 600;
    src: url("../fonts/space/SpaceGrotesk-SemiBold.ttf");
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Space Grotesk';
    font-style : normal;
    font-weight: 700;
    src: url("../fonts/space/SpaceGrotesk-Bold.ttf");
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Space Grotesk';
    font-style : normal;
    font-weight: 800;
    src: url("../fonts/space/SpaceGrotesk-VariableFont_wght.ttf");
    font-display: swap;
  }

  .power-200{
    font-family: 'Power Grotesk';
    font-style: normal;
    font-weight: 200;
  }
  .power-200-italic {
    font-family: 'Power Grotesk';
    font-style: italic;
    font-weight: 200;
  }
  .power-300{
    font-family: 'Power Grotesk';
    font-style: normal;
    font-weight: 300;
  }
  .power-300-italic{
    font-family: 'Power Grotesk';
    font-style: italic;
    font-weight: 300;
  }
  .power-400{
    font-family: 'Power Grotesk';
    font-style: normal;
    font-weight: 400;
  }
  .power-400-italic{
    font-family: 'Power Grotesk';
    font-style: italic;
    font-weight: 400;
  }
  .power-500{
    font-family: 'Power Grotesk';
    font-style: normal;
    font-weight: 500;
  }
  .power-500-italic{
    font-family: 'Power Grotesk';
    font-style: italic;
    font-weight: 500;
  }
  .power-600{
    font-family: 'Power Grotesk';
    font-style: normal;
    font-weight: 600;
  }
  .power-600-italic{
    font-family: 'Power Grotesk';
    font-style: italic;
    font-weight: 600;
  }
  .power-700{
    font-family: 'Power Grotesk';
    font-style: normal;
    font-weight: 700;
  }
  .power-700-italic{
    font-family: 'Power Grotesk';
    font-style: italic;
    font-weight: 700;
  }
  .power-800{
    font-family: 'Power Grotesk';
    font-style: normal;
    font-weight: 800;
  }
  .power-800-italic{
    font-family: 'Power Grotesk';
    font-style: italic;
    font-weight: 800;
  }
  .power-900{
    font-family: 'Power Grotesk';
    font-style: normal;
    font-weight: 900;
  }
  .power-900-italic{
    font-family: 'Power Grotesk';
    font-style: italic;
    font-weight: 900;
  }

  .space-300{
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 300;
  }
  .space-400{
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 400;
  }
  .space-500{
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 500;
  }
  .space-600{
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 600;
  }
  .space-700{
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
  }
  .space-800{
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 800;
  }